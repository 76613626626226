@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  /* this is causing unnecessary scrollbars */
  /* html,
  body {
    min-height: 100vh;
  } */
  div#root > .dark {
    @apply bg-gray-900;
  }
  /* LAYOUT */
  #main {
    /* @apply mt-16; */
    margin-top: 4rem;
    /* height: 100%; */
    max-height: calc(100vh - 4rem);
    overflow: scroll;
  }
  .pill {
    @apply flex w-fit items-center justify-center rounded-full border border-primary/90 px-2 py-1 text-sm text-primary/90 hover:bg-primary hover:text-white focus:border-purple-300 focus:ring;
  }
  .fancy-list {
    @apply flex w-fit items-center justify-center rounded px-2 py-1 text-sm text-primary/90 hover:border-purple-300 hover:ring;
  }
  /* this is causing trouble in controlling scrollbars */
  /* #content {
    min-height: 100vh;
  } */
  #app-sidebar {
    position: relative;
    box-sizing: border-box;
    height: auto;
  }
  /* this is causing trouble in the accordion on FAQ */
  button {
    /* @apply rounded-md bg-primary px-4 py-2 text-sm font-semibold text-white shadow-sm hover:bg-primary/90 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary; */
    @apply rounded-md;
  }
  button:disabled,
  button[disabled] {
    @apply bg-gray-300 text-gray-500;
    pointer-events: none;
  }
  button.secondary {
    @apply rounded-md bg-white px-4 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50;
  }
  button.circular {
    @apply rounded-full bg-primary p-2 text-white shadow-sm hover:bg-primary/90 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary;
  }
  button[type="submit"] {
    @apply rounded-md bg-primary px-4 py-2 text-sm font-semibold text-white shadow-sm hover:bg-primary/90 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary;
  }
  button[type="submit"]:disabled,
  button[type="submit"][disabled] {
    @apply bg-gray-300 text-gray-500;
    pointer-events: none;
  }
  button[type="submit"].secondary {
    @apply rounded-md bg-white px-4 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50;
  }
  button[type="submit"].circular {
    @apply rounded-full bg-primary p-2 text-white shadow-sm hover:bg-primary/90 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary;
  }
  .button {
    @apply rounded-md bg-primary px-4 py-2 text-sm font-semibold text-white shadow-sm hover:bg-primary/90 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary;
  }
  .button:disabled,
  .button[disabled] {
    @apply bg-gray-300 text-gray-500;
    pointer-events: none;
  }
  .button.secondary {
    @apply rounded-md bg-white px-4 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50;
  }
  .button.circular {
    @apply rounded-full bg-primary p-2 text-white shadow-sm hover:bg-primary/90 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary;
  }
  .selectbox button {
    @apply bg-white text-sm text-gray-900 hover:bg-gray-100;
  }
  .selectbox .button {
    @apply bg-white text-sm text-gray-900 hover:bg-gray-100;
  }
  .selectbox button span {
    font-weight: normal;
  }
  .selectbox .button span {
    font-weight: normal;
  }
  /* Typography */
  h1.page-title {
    @apply my-4 text-5xl font-extrabold dark:text-white;
  }
  h2.page-title {
    @apply text-4xl font-bold dark:text-white;
  }
  h3.page-title {
    @apply text-3xl font-bold dark:text-white;
  }
  /* Pages */
  .terms__video {
    max-width: 1440px;
  }
}
